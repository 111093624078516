import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {ConfirmationComponent} from './pages/confirmation/confirmation.component';
import {ReviewComponent} from './pages/review/review/review.component';
import {EidLandingComponent} from './pages/eid/eid-landing/eid-landing.component';
import {EidConfirmIdentityComponent} from './pages/eid/eid-confirm-identity/eid-confirm-identity.component';
import {EidQuestionsComponent} from './pages/eid/eid-questions/eid-questions.component';
import {EidParentComponent} from './pages/eid/eid-parent/eid-parent.component';
import {SessionComponent} from './pages/session/session.component';
import {DirectAccessGuard} from './direct-access-guard';
import {WdaComponent} from './pages/wda/wda/wda.component';
import {EsignatureLandingComponent} from './pages/esignature-landing/esignature-landing.component';
import {ConsentComponent} from './pages/consent/consent.component'
import {AdditionalSignaturesComponent} from './pages/additional-signatures/additional-signatures.component';
import {SomethingWentWrongComponent} from './pages/something-went-wrong/something-went-wrong.component';
import {WdaLandingComponent} from './pages/wda/wda-landing/wda-landing.component';
import {WdaParentComponent} from './pages/wda/wda-parent/wda-parent.component';
import {ConfirmAddressComponent} from './pages/confirm-address/confirm-address.component';
import {BeforeYouStartOneComponent} from './pages/before-you-start-one/before-you-start-one.component';
import {BeforeYouStartTwoComponent} from './pages/before-you-start-two/before-you-start-two.component';
import {AppDataResolver} from './resolvers/app-data.resolver';
import {AddressInformationComponent} from './pages/address-information/address-information.component';
import {PersonalInformationComponent} from './pages/personal-information/personal-information.component';
import {FinancialAssetsComponent} from './pages/financial-assets/financial-assets.component';
import {SpouseInformationComponent} from './pages/spouse-information/spouse-information.component';
import {HousingSituationComponent} from './pages/housing-situation/housing-situation.component';
import {UnauthorizedComponent} from './pages/unauthorized/unauthorized.component';
import {LoginComponent} from './pages/login/login.component';
import {ChildrenInformationComponent} from './pages/children-information/children-information.component';
import {HouseholdIncomeComponent} from './pages/household-income/household-income.component';
import {AdditionalInformationComponent} from './pages/additional-information/additional-information.component';
import {EarnedIncomeComponent} from './pages/earned-income/earned-income.component';
import {BankDetailsComponent} from './pages/bank-details/bank-details.component';
import {SponsorshipComponent} from './pages/sponsorship/sponsorship.component';
import {AscLandingComponent} from './pages/app-status/asc-landing/asc-landing.component';
import {AscResultsComponent} from './pages/app-status/asc-results/asc-results.component';
import {GlobalErrorComponent} from './pages/global-error/global-error.component';
import {ResumeApplicationGuard} from './resume-application.guard';
import {LoginConfirmationComponent} from './pages/login-confirmation/login-confirmation.component';
import {ResumeApplicationComponent} from './pages/resume-application/resume-application.component';
import {CreateAccountComponent} from './pages/create-account/create-account.component';
import {BrowseBackGuard} from './browse-back-guard';
import {PageInfo} from './models/page-map';
import {LoginErrorComponent} from './pages/login-error/login-error.component';
import {InprogressOrExpiredApplicationComponent} from
    './pages/inprogress-or-expired-application/inprogress-or-expired-application.component';
import {ProgramRecommendationComponent} from './pages/program-selection/program-recommendation.component';
import {AscPendingComponent} from './pages/app-status/asc-pending/asc-pending.component';
import {FinancialIndependenceComponent} from './pages/financial-independence/financial-independence.component';
import { AddressInfoComponent } from './pages/address-info/address-info.component';
import { AddressSummaryComponent } from './pages/address-summary/address-summary.component';
import { AddressReviewComponent } from './pages/address-review/address-review.component';

const routes: Routes = [
  // TODO: will be revisited when multiple application types are supported
  //  {path: 'programs', component: ProgramsComponent},
  // preventBrowseBack should be set on the page beyond that browser back-button is not allowed.
  // eg. preventBrowseBack is turned on at the Review Page.
  {
    path: 'intake/' + PageInfo.home,
    pathMatch: 'full',
    component: BeforeYouStartOneComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.bus1',
    data: {allowToSave: false, pageId: PageInfo.home}
  },
  {
    path: 'intake/' + PageInfo.bya,
    pathMatch: 'full',
    component: BeforeYouStartTwoComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.bus2',
    data: {allowToSave: true, pageId: PageInfo.bya}
  },
  {
    path: 'intake/' + PageInfo.createAccount,
    pathMatch: 'full',
    component: CreateAccountComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.createaccount',
    data: {allowToSave: false, pageId: PageInfo.createAccount}
  },
  {
    path: 'intake/' + PageInfo.addressInfo,
    pathMatch: 'full',
    component: AddressInfoComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.addressinfo',
    data: {allowToSave: false, pageId: PageInfo.addressInfo}
  },
  {
    path: 'intake/' + PageInfo.addressReview,
    pathMatch: 'full',
    component: AddressReviewComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.addressinfo',
    data: {allowToSave: false, pageId: PageInfo.addressReview}
  },
  {
    path: 'intake/' + PageInfo.confirmAddress,
    pathMatch: 'full',
    component: AddressSummaryComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.confirmaddress',
    data: {allowToSave: true, pageId: PageInfo.confirmAddress}
  },
  {
    path: 'intake/' + PageInfo.personalInfo,
    pathMatch: 'full',
    component: PersonalInformationComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.personalinfo',
    data: {allowToSave: true, pageId: PageInfo.personalInfo}
  },
  {
    path: 'intake/' + PageInfo.spouseInfo,
    pathMatch: 'full',
    component: SpouseInformationComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.spouseinfo',
    data: {allowToSave: true, pageId: PageInfo.spouseInfo}
  },
  {
    path: 'intake/' + PageInfo.childrenInfo,
    pathMatch: 'full',
    component: ChildrenInformationComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.childreninfo',
    data: {allowToSave: true, pageId: PageInfo.childrenInfo}
  },
  {
    path: 'intake/' + PageInfo.additionalInfo,
    pathMatch: 'full',
    component: AdditionalInformationComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    data: {allowToSave: true, pageId: PageInfo.additionalInfo}
  },
  {
    path: 'intake/' + PageInfo.financialIndependence,
    pathMatch: 'full',
    component: FinancialIndependenceComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.financialindependence',
    data: {allowToSave: true, pageId: PageInfo.financialIndependence}
  },
  {
    path: 'intake/' + PageInfo.sponsorship,
    pathMatch: 'full',
    component: SponsorshipComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.sponsorship',
    data: {allowToSave: true, pageId: PageInfo.sponsorship}
  },
  {
    path: 'intake/' + PageInfo.financialAssets,
    pathMatch: 'full',
    component: FinancialAssetsComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.financialassets',
    data: {allowToSave: true, pageId: PageInfo.financialAssets}
  },
  {
    path: 'intake/' + PageInfo.bankDetails,
    pathMatch: 'full',
    component: BankDetailsComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.bankdetails',
    data: {allowToSave: true, pageId: PageInfo.bankDetails}
  },
  {
    path: 'intake/' + PageInfo.housingSituation,
    pathMatch: 'full',
    component: HousingSituationComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.housingsituation',
    data: {allowToSave: true, pageId: PageInfo.housingSituation}
  },
  {
    path: 'intake/' + PageInfo.earnedIncome,
    pathMatch: 'full',
    component: EarnedIncomeComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.earnedincome',
    data: {allowToSave: true, pageId: PageInfo.earnedIncome}
  },
  {
    path: 'intake/' + PageInfo.householdIncome,
    pathMatch: 'full',
    component: HouseholdIncomeComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.otherincome',
    data: {allowToSave: true, pageId: PageInfo.householdIncome}
  },
  // {
  //   path: 'intake/' + PageInfo.confirmAddress,
  //   pathMatch: 'full',
  //   component: ConfirmAddressComponent,
  //   resolve: {appData: AppDataResolver},
  //   canActivate: [BrowseBackGuard, DirectAccessGuard],
  //   title: 'headerTitles.confirmaddress',
  //   data: {allowToSave: false, pageId: PageInfo.confirmAddress}
  // },
  {
    path: 'intake/' + PageInfo.review,
    component: ReviewComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.review',
    data: {allowToSave: true, pageId: PageInfo.review}
  },
  {
    path: 'intake/' + PageInfo.inprogressOrExpiredApplication,
    pathMatch: 'full',
    component: InprogressOrExpiredApplicationComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    data: {allowToSave: false, preventBrowseBack: true, pageId: PageInfo.inprogressOrExpiredApplication}
  },
  {path: PageInfo.confirmation, component: ConfirmationComponent, title: 'headerTitles.confirmation',
          data: {pageId: PageInfo.confirmation}},
  {path: PageInfo.globalError, component: GlobalErrorComponent, canActivate: [BrowseBackGuard, DirectAccessGuard], title: 'headerTitles.globalerror'},
  {path: 'applicationstatuschecker', component: AscLandingComponent, title: 'headerTitles.appstatuschecker'},
  {path: 'applicationstatuspending', component: AscPendingComponent, title: 'headerTitles.appstatuschecker'},
  {path: 'applicationstatusresults', component: AscResultsComponent, title: 'headerTitles.appstatus'},
  {
    path: 'intake/' + PageInfo.programRecommendation,
    component: ProgramRecommendationComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.programrecommendation',
    data: {allowToSave: true, preventBrowseBack: true, pageId: PageInfo.programRecommendation},
  },
  {path: PageInfo.consent,
    component: ConsentComponent,
    resolve: {appData: AppDataResolver},
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.declaration',
    data: {allowToSave: false, preventBrowseBack: true, pageId: PageInfo.consent},
  },
  {
    path: PageInfo.wda,
    component: WdaParentComponent,
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.demographic',
    data: {allowToSave: false, preventBrowseBack: true, pageId: PageInfo.wda},
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'landing',
        data: {allowToSave: false, preventBrowseBack: true, pageId: PageInfo.wda}
      },
      {
        path: 'landing',
        component: WdaLandingComponent,
        resolve: {appData: AppDataResolver},
        canActivate: [BrowseBackGuard, DirectAccessGuard],
        data: {allowToSave: false, preventBrowseBack: true, pageId: PageInfo.wda}
      },
      {
        path: 'questions/:applicantType',
        component: WdaComponent,
        resolve: {appData: AppDataResolver},
        canActivate: [BrowseBackGuard, DirectAccessGuard],
        data: {allowToSave: false, preventBrowseBack: true, pageId: PageInfo.wda}
      },
      {
        path: 'questions/:applicantType/:childIndex',
        component: WdaComponent,
        resolve: {appData: AppDataResolver},
        canActivate: [BrowseBackGuard, DirectAccessGuard],
        data: {allowToSave: false, preventBrowseBack: true, pageId: PageInfo.wda}
      }
    ]
  },
  {
    path: 'eid', component: EidParentComponent,
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    data: {preventBrowseBack: true, checkValidApp: true, pageId: PageInfo.eidPage},
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'landing'
      },
      {
        path: 'landing',
        component: EidLandingComponent,
        canActivate: [BrowseBackGuard, DirectAccessGuard],
        data: {preventBrowseBack: true, checkValidApp: true, pageId: PageInfo.eidPage},
        title: 'headerTitles.confirmidentityonline'
      },
      {path: 'confirm',
        component: EidConfirmIdentityComponent,
        canActivate: [BrowseBackGuard, DirectAccessGuard],
        data: {preventBrowseBack: true, checkValidApp: true, pageId: PageInfo.eidPage},
        title: 'headerTitles.confirmidentityonline'
      },
      {path: 'questions/:personId',
        component: EidQuestionsComponent,
        canActivate: [BrowseBackGuard, DirectAccessGuard],
        data: {preventBrowseBack: true, checkValidApp: true, pageId: PageInfo.eidQuestions},
        title: 'headerTitles.confirmidentity'
      }
    ]
  },
  {
    path: PageInfo.additionalSignatures,
    component: AdditionalSignaturesComponent,
    canActivate: [BrowseBackGuard],
    data: {preventBrowseBack: true, checkValidApp: false, pageId: PageInfo.additionalSignatures},
    title: 'headerTitles.additionalsignature'
  },
  {
    path: 'en/'+PageInfo.additionalSignatures,
    component: AdditionalSignaturesComponent,
    canActivate: [BrowseBackGuard],
    data: {preventBrowseBack: true, checkValidApp: false, pageId: PageInfo.additionalSignatures},
    title: 'headerTitles.additionalsignature'
  },
  {
    path: 'fr/'+PageInfo.additionalSignatures,
    component: AdditionalSignaturesComponent,
    canActivate: [BrowseBackGuard],
    data: {preventBrowseBack: true, checkValidApp: false, pageId: PageInfo.additionalSignatures},
    title: 'headerTitles.additionalsignature'
  },
  {
    path: 'esignature',
    component: EsignatureLandingComponent,
    canActivate: [BrowseBackGuard, DirectAccessGuard],
    title: 'headerTitles.signature',
    data: {preventBrowseBack: true, checkValidApp: true, pageId: PageInfo.signatureLanding},
  },
  {
    matcher: (url) => {
      if (url.length && url.length === 2 && url[1].path === 'confirmation') {
        return {
          consumed: url
        };
      }
      return null;
    },
    redirectTo: 'confirmation', pathMatch: 'full'
  },
  {
    matcher: (url) => {
      if (url.length && url.length === 2 && url[1].path === 'applicationstatuschecker') {
        return {
          consumed: url
        };
      }
      return null;
    },
    redirectTo: 'applicationstatuschecker', pathMatch: 'full'
  },
  {
    matcher: (url) => {
      if (!url.length || url[0].path.match(/^(en)|(fr)$/gm)) {
        return {
          consumed: url
        };
      }
      return null;
    },
    redirectTo: '/intake/' + PageInfo.home, pathMatch: 'full'
  },
  {
    matcher: (url) => {
      if (url.length && url.length === 3 && url[2].path === 'before-you-start1') {
        return {
          consumed: url
        };
      }
      return null;
    },
    redirectTo: '/intake/' + PageInfo.home, pathMatch: 'full'
  },
  {path: PageInfo.error, component: PageNotFoundComponent},
  {path: PageInfo.somethingWentWrong, component: SomethingWentWrongComponent},
  {path: 'error-page-with-ref-number', component: ConfirmationComponent, canActivate: [DirectAccessGuard]},
  {path: 'session-timeout', component: SessionComponent},
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'caseworkerlogin', component: LoginComponent}, // authenticate internal OPS users
  // handle the request to complete the previously-saved applications
  {path: 'resume-application/:source', component: ResumeApplicationComponent, canActivate: [ResumeApplicationGuard]},
  // to collect the reference-id to resume the previously-saved applications
  {path: 'login-confirmation', component: LoginConfirmationComponent, canActivate: [DirectAccessGuard], title: 'headerTitles.login-confirmation'},
  {path: 'login-error', component: LoginErrorComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
