import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApplicationResponse} from '../models/application-response.model';
import {IntakeService} from './intake.service';
import {ApplicationAnswers} from '../models/data.model';
import {SubmissionResponse} from '../models/submission-response.model'
import {ResumeApplicationResponse} from '../models/resume-application-response';
import {LastAccessedInfoResponse} from '../models/last-accessed-info-response';

@Injectable({
  providedIn: 'root'
})
export class IntakeServiceImpl implements IntakeService {

  isApplyingForSomeoneElse: boolean|undefined;
  isSpouseRequired: boolean|undefined;
  isChildRequired: boolean| undefined;
  isSponsorshipRequired: boolean| undefined;
  isFinIndependencePageRequired: boolean| undefined;
  shouldExcludeDbd: boolean| undefined;
  isRelatedToLocalOfficeAssistance: boolean| undefined;

  constructor(private http: HttpClient) {
  }

  submitApplication(): Observable<SubmissionResponse> {
    return this.http.post<SubmissionResponse>(`/v1/application/submit`,
      {}, {withCredentials: true})
  }

  submitQuestionnaire(dataSharingForMLITSD: string): Observable<ApplicationResponse> {
    // tslint:disable-next-line:max-line-length
    return this.http.post<ApplicationResponse>(`/v1/application/submit-questionnaire`, {dataSharingForMLITSD}, {withCredentials: true});
  }

  saveApplication(answers: ApplicationAnswers, page: string): Observable<void> {
    this.formatUCIValues(answers)
    return this.http.put<void>(`/v1/application/${page}`, answers, {withCredentials: true});
  }

  saveToResume(answers: ApplicationAnswers, page: string): Observable<void> {
    this.formatUCIValues(answers)
    return this.http.put<void>(`/v1/application/save/${page}`, answers, {withCredentials: true});
  }

  saveProgress(page: string): Observable<void> {
    return this.http.put<void>(`/v1/application/save/progress/${page}`, {},{withCredentials: true});
  }

  getApplication(page: string): Observable<ApplicationAnswers> {
    return this.http.get<ApplicationAnswers>(`/v1/application/${page}`, {withCredentials: true});
  }

  getLastAccessedInfo(): Observable<LastAccessedInfoResponse> {
    return this.http.get<LastAccessedInfoResponse>(`/v1/lastAccessedInfo`,{withCredentials: true});
  }

  resumeApplication(): Observable<ResumeApplicationResponse> {
    return this.http.get<ResumeApplicationResponse>(`/v1/resume-application`, {withCredentials: true});
  }

  getSavedApplication(): Observable<ApplicationAnswers> {
    return this.http.get<ApplicationAnswers>(`/v1/application-by-ref-id`,{withCredentials: true});
  }

  logout(): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<void>(`/v1/logout`, new Blob([], {type: 'application/json'}),
      {withCredentials: true, headers});
  }

  formatUCIValues(answers: ApplicationAnswers): void {
    const pattern = new RegExp('^([0-9]{8}|[0-9]{10})$') // without hyphen and length 8 or 10
    if (answers.jsonData.immigrationFileNumber && pattern.test(answers.jsonData.immigrationFileNumber)) {
      answers.jsonData.immigrationFileNumber = this.formatUCIValue(answers.jsonData.immigrationFileNumber)
    }

    if (answers.jsonData.spouseImmigrationFileNumber && pattern.test(answers.jsonData.spouseImmigrationFileNumber)) {
      answers.jsonData.spouseImmigrationFileNumber = this.formatUCIValue(answers.jsonData.spouseImmigrationFileNumber)
    }

    if (answers.jsonData.childList) {
      answers.jsonData.childList.forEach(child => {
        if (child.childImmigrationFileNumber && pattern.test(child.childImmigrationFileNumber)) {
          child.childImmigrationFileNumber = this.formatUCIValue(child.childImmigrationFileNumber)
        }
      })
    }
  }

  private formatUCIValue(value: string) {
    if (value.length === 8) {
      return value.replace(/(\d{4})(?=\d)/g, '$1-');
    } else {
      return value.replace(/^(\d{2})(\d{4})(\d{4})$/g, '$1-$2-$3');
    }
  }

  getIsApplyingForSomeoneElse(): boolean {
    return this.isApplyingForSomeoneElse;
  }

  getIsRelatedToLocalOfficeAssistance(): boolean {
    return this.isRelatedToLocalOfficeAssistance;
  }

  setIsRelatedToLocalOfficeAssistance(localOfficeAssistanceVal: boolean): void {
    this.isRelatedToLocalOfficeAssistance = localOfficeAssistanceVal;
  }

  setIsApplyingForSomeoneElse(SomeoneElse: boolean): void {
    this.isApplyingForSomeoneElse = SomeoneElse;
  }

  getAppChildRequired(): boolean {
    return this.isChildRequired;
  }

  getAppSpouseRequired(): boolean {
    return this.isSpouseRequired;
  }

  setAppChildRequired(required: boolean): void {
    this.isChildRequired = required;
  }

  setAppSpouseRequired(required: boolean): void {
    this.isSpouseRequired = required;
  }

  getSponsorshipRequired(): boolean {
    return this.isSponsorshipRequired;
  }

  setSponsorshipRequired(required: boolean): void {
    this.isSponsorshipRequired = required;
  }

  getFinIndependencePageRequired(): boolean {
    return this.isFinIndependencePageRequired;
  }

  setFinIndependencePageRequired(required: boolean): void {
    this.isFinIndependencePageRequired = required;
  }

  getShouldExcludeDbd(): boolean {
    return this.shouldExcludeDbd;
  }

  setShouldExcludeDbd(exclude: boolean): void {
    this.shouldExcludeDbd = exclude
  }

}
