import { MccssAddressData, MccssAddressForm } from '@mccss/pclookup-common-ui';
import { ApplicationAnswers } from "../models/data.model";

/**
 * Helper class that:
 *  - Build MccssAddressData from Application Answers which will be used in UI
 *  - Build Application Answers from address data entered in UI, which will be persisted to database
 */
export class AddressHelper {

  /**
   * Build MccssAddressData from Application Answers which will be used in UI
   * @param answers The application Answers retrieved from database
   */
  static buildAddressFromAnswers(answers: any): MccssAddressData {
    let address: MccssAddressData = new MccssAddressData();
    address.isTrusteeAddressEnabled = AddressHelper.isTrusteeAddressEnabled(answers.jsonData)
    address.isMailingAddressEnabled = !address.isTrusteeAddressEnabled;

    address.homeAddress = AddressHelper.buildHomeAddressFromAnswers(answers.jsonData);

    if (address.isMailingAddressEnabled) {
      if (!answers.jsonData.addressSame) {
        address.isMailingAddressSameAsHome = !address.homeAddress;
        address.mailingAddress = AddressHelper.buildMailingAddressFromAnswers(answers.jsonData);
      } else {
        address.isMailingAddressSameAsHome = true;
      }
    }

    if (address.isTrusteeAddressEnabled) {
      address.trusteeAddress = AddressHelper.buildTrusteeAddressFromAnswers(answers.jsonData);
    }

    return address;
  }

  static buildHomeAddressFromAnswers(answers: any): MccssAddressForm | null {
    if (!answers.deliveryType) {
      // No existing home address
      return null;
    }

    // Build the home address from the answers
    let homeAddress = new MccssAddressForm();
    homeAddress.deliveryType = answers.deliveryType;

    homeAddress.unitNumber = answers.apartmentNumber;
    homeAddress.streetNumber = answers.streetNumber;
    homeAddress.streetNumberSuffix = answers.streetNumberSuffix;
    homeAddress.streetName = answers.streetName;
    homeAddress.streetType = answers.streetType;
    homeAddress.streetDirection = answers.direction;

    homeAddress.ruralRoute = answers.ruralRoute;
    homeAddress.municipality = answers.cityOrTown;
    homeAddress.province = answers.province;
    homeAddress.postalCode = answers.postalCode;

    homeAddress.isApartment = answers.isApartment ? answers.isApartment : 'no';
    homeAddress.isManualEntry = answers.isManualEntry? answers.isManualEntry : 'no';

    return homeAddress;
  }

  static buildMailingAddressFromAnswers(answers: any): MccssAddressForm | null {
    if (!answers.mDeliveryType) {
      // No existing Mailing address
      return null;
    }

    // Build the Mailing address from the answers
    let mailingAddress = new MccssAddressForm();
    mailingAddress.deliveryType = answers.mDeliveryType;

    mailingAddress.unitNumber = answers.mApartmentNumber;
    mailingAddress.streetNumber = answers.mStreetNumber;
    mailingAddress.streetNumberSuffix = answers.mStreetNumberSuffix;
    mailingAddress.streetName = answers.mStreetName;
    mailingAddress.streetType = answers.mStreetType;
    mailingAddress.streetDirection = answers.mDirection;

    mailingAddress.deliveryInstallationType = answers.mGeneralDelivery;
    mailingAddress.boxNumber = answers.mPoBox;
    mailingAddress.deliveryInstallationName = answers.mStation;
    mailingAddress.ruralRoute = answers.mRuralRoute;
    mailingAddress.municipality = answers.mCityOrTown;
    mailingAddress.province = answers.mProvince;
    mailingAddress.postalCode = answers.mPostalCode;

    mailingAddress.isApartment = answers.mIsApartment ? answers.mIsApartment : 'no';
    mailingAddress.isManualEntry = answers.mIsManualEntry? answers.mIsManualEntry : 'no';

    return mailingAddress;
  }

  static buildTrusteeAddressFromAnswers(answers: any): MccssAddressForm | null {
    if (!answers.trusteeDeliveryType) {
      // No existing Trustee address
      return null;
    }

    // Build the Trustee address from the answers
    let trusteeAddress = new MccssAddressForm();
    trusteeAddress.deliveryType = answers.trusteeDeliveryType;

    trusteeAddress.unitNumber = answers.trusteeApartmentNumber;
    trusteeAddress.streetNumber = answers.trusteeStreetNumber;
    trusteeAddress.streetNumberSuffix = answers.trusteeStreetNumberSuffix;
    trusteeAddress.streetName = answers.trusteeStreetName;
    trusteeAddress.streetType = answers.trusteeStreetType;
    trusteeAddress.streetDirection = answers.trusteeDirection;
    trusteeAddress.deliveryInstallationType = answers.trusteeGeneralDelivery;
    trusteeAddress.boxNumber = answers.trusteePoBox;
    trusteeAddress.deliveryInstallationName = answers.trusteeStation;
    trusteeAddress.ruralRoute = answers.trusteeRuralRoute;
    trusteeAddress.municipality = answers.trusteeCityOrTown;
    trusteeAddress.province = answers.trusteeProvince;
    trusteeAddress.postalCode = answers.trusteePostalCode;

    trusteeAddress.isApartment = answers.trusteeIsApartment ? answers.trusteeIsApartment : 'no';
    trusteeAddress.isManualEntry = answers.trusteeIsManualEntry? answers.trusteeIsManualEntry : 'no';

    return trusteeAddress;
  }

  static isTrusteeAddressEnabled(answers: any): boolean {
    return answers
        && answers.applyingForYourselfOrSomeoneElse === 'APPLICATION_OTHER'
        && answers.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified';
  }

  static buildAddressAnswers(address: MccssAddressData | null): any {
    // Start with the home address
    let addressAnswers = {
      ...AddressHelper.buildHomeAddressAnswers(address?.homeAddress)
    };
  
    // Add mailing address data if enabled
    if (address?.isMailingAddressEnabled) {
      if (address.isMailingAddressSameAsHome) {
        addressAnswers = { ...addressAnswers, addressSame: ["yes"] };
      } else {
        addressAnswers = { ...addressAnswers, ...AddressHelper.buildMailingAddressAnswers(address.mailingAddress) };
      }
    }
  
    // Add trustee address data if enabled
    if (address?.isTrusteeAddressEnabled) {
      addressAnswers = {
        ...addressAnswers,
        ...AddressHelper.buildTrusteeAddressAnswers(address.trusteeAddress)
      };
    }
  
    // Return the final JSON object
    return addressAnswers;
  }

  static buildHomeAddressAnswers(homeAddress: MccssAddressForm | null | undefined): any {
    let answers = homeAddress ? {
      deliveryType: homeAddress.deliveryType,
      ruralRoute: homeAddress.ruralRoute,
      apartmentNumber: homeAddress.unitNumber,
      streetNumber: homeAddress.streetNumber,
      streetNumberSuffix: homeAddress.streetNumberSuffix,
      streetName: homeAddress.streetName,
      streetType: homeAddress.streetType,
      direction: homeAddress.streetDirection,
      cityOrTown: homeAddress.municipality,
      province: homeAddress.province,
      postalCode: homeAddress.postalCode ? homeAddress.postalCode.replace(/\s+/g, '') : '',
      
      isApartment: homeAddress.isApartment,
      isManualEntry: homeAddress.isManualEntry
    } : {};

    return AddressHelper.removeEmptyFields(answers);
  }

  static buildMailingAddressAnswers(mailingAddress: MccssAddressForm | null): any {
    let answers = mailingAddress ? {
      mDeliveryType: mailingAddress.deliveryType,
      mPoBox: mailingAddress.boxNumber,
      mStation: mailingAddress.deliveryInstallationName,
      mGeneralDelivery: mailingAddress.deliveryInstallationType,
      mRuralRoute: mailingAddress.ruralRoute,
      mApartmentNumber: mailingAddress.unitNumber,
      mStreetNumber: mailingAddress.streetNumber,
      mStreetNumberSuffix: mailingAddress.streetNumberSuffix,
      mStreetName: mailingAddress.streetName,
      mStreetType: mailingAddress.streetType,
      mDirection: mailingAddress.streetDirection,
      mCityOrTown: mailingAddress.municipality,
      mProvince: mailingAddress.province,
      mPostalCode: mailingAddress.postalCode ? mailingAddress.postalCode.replace(/\s+/g, '') : '',

      mIsApartment: mailingAddress.isApartment,
      mIsManualEntry: mailingAddress.isManualEntry
    } : {};

    return AddressHelper.removeEmptyFields(answers);
  }

  static buildTrusteeAddressAnswers(trusteeAddress: MccssAddressForm | null): any {
    let answers = trusteeAddress ?  {
      trusteeDeliveryType: trusteeAddress.deliveryType,
      trusteePoBox: trusteeAddress.boxNumber,
      trusteeStation: trusteeAddress.deliveryInstallationName,
      trusteeGeneralDelivery: trusteeAddress.deliveryInstallationType,
      trusteeRuralRoute: trusteeAddress.ruralRoute,
      trusteeApartmentNumber: trusteeAddress.unitNumber,
      trusteeStreetNumber: trusteeAddress.streetNumber,
      trusteeStreetNumberSuffix: trusteeAddress.streetNumberSuffix,
      trusteeStreetName: trusteeAddress.streetName,
      trusteeStreetType: trusteeAddress.streetType,
      trusteeDirection: trusteeAddress.streetDirection,
      trusteeCityOrTown: trusteeAddress.municipality,
      trusteeProvince: trusteeAddress.province,
      trusteePostalCode: trusteeAddress.postalCode ? trusteeAddress.postalCode.replace(/\s+/g, '') : '',

      trusteeIsApartment: trusteeAddress.isApartment,
      trusteeIsManualEntry: trusteeAddress.isManualEntry
    } : {};

    return AddressHelper.removeEmptyFields(answers);
  }

  static removeAllAddressFields(appAnswers: ApplicationAnswers) {
    if (appAnswers && appAnswers.jsonData) {
      ADDRESS_FIELDS.forEach(field => {
        if (field in appAnswers.jsonData) {
          delete appAnswers.jsonData[field];
        }
      });
    }
  }

  private static removeEmptyFields(obj: Record<string, any>): Record<string, any> {
    const filteredEntries = Object.entries(obj).filter(
      ([_, value]) => value !== null && value !== undefined && (typeof value !== 'string' || value.trim() !== '')
    );
  
    const result: Record<string, any> = {};
    for (const [key, val] of filteredEntries) {
      result[key] = val;
    }
  
    return result;
  }
}

export const ADDRESS_FIELDS: string[] = [
  'addressSame',
  
  // Home address fields
  'deliveryType',
  'ruralRoute',
  'apartmentNumber',
  'streetNumber',
  'streetNumberSuffix',
  'streetName',
  'streetType',
  'direction',
  'cityOrTown',
  'province',
  'postalCode',
  'isApartment',
  'isManualEntry',

  // Mailing address fields
  'mDeliveryType',
  'mPoBox',
  'mStation',
  'mGeneralDelivery',
  'mRuralRoute',
  'mApartmentNumber',
  'mStreetNumber',
  'mStreetNumberSuffix',
  'mStreetName',
  'mStreetType',
  'mDirection',
  'mCityOrTown',
  'mProvince',
  'mPostalCode',
  'mIsApartment',
  'mIsManualEntry',

  // Trustee address fields
  'trusteeDeliveryType',
  'trusteePoBox',
  'trusteeStation',
  'trusteeGeneralDelivery',
  'trusteeRuralRoute',
  'trusteeApartmentNumber',
  'trusteeStreetNumber',
  'trusteeStreetNumberSuffix',
  'trusteeStreetName',
  'trusteeStreetType',
  'trusteeDirection',
  'trusteeCityOrTown',
  'trusteeProvince',
  'trusteePostalCode',
  'trusteeIsApartment',
  'trusteeIsManualEntry'
];

