<div attr.name="{{fieldName}}" class="ontario-form-group" [formGroup]="form">
  <fieldset class="ontario-fieldset">
    <label *ngIf="showLabel" class="ontario-label" id="{{id}}">{{label | translate : labelParam}}</label>
    <sd-hint-text *ngIf="additionalHintText"
                  id="{{id}}-additional-hintText"
                  [text]="additionalHintText">
    </sd-hint-text>
    <sd-hint-text *ngIf="hintText && !hintExpandableContent"
                  id="{{id}}-question-hintText"
                  [text]="hintText">
    </sd-hint-text>
    <sd-alert-err *ngIf="errorMessage" [errorId]="id.concat('-err')" [errorMessage]="errorMessage | translate">
    </sd-alert-err>
    <div [formArrayName]="controlName" attr.aria-labelledby="{{id}}" class="ontario-checkboxes">
      <div *ngFor="let item of items.controls; index as i; let lastItem = last" [ngClass]="setBackgroundClass()">
        <div *ngIf="descriptionText" class="description-text ontario-padding-bottom-16-!">
          <span [innerHTML]="descriptionText | translate"></span>
        </div>
        <div class="ontario-checkboxes__item">
          <input id="{{id}}-checkbox{{labelParam?.index}}-option{{i + 1}}" type="checkbox" class="ontario-checkboxes__input" [formControlName]="i">
          <label id="{{id}}-checkbox{{labelParam?.index}}-label{{i + 1}}" class="ontario-checkboxes__label" for="{{id}}-checkbox{{labelParam?.index}}-option{{i + 1}}"
                 [innerHTML]="options[i].label | translate: options[i].labelParam "></label>
          <div *ngIf="displayDivider && !lastItem" class="divider my-4" aria-hidden="true"></div>
        </div>
      </div>
    </div>
  </fieldset>
</div>
