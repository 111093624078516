import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {IntakeService} from '../services/intake.service';
import {catchError, map, Observable, of, switchMap, tap} from 'rxjs';
import {ApplicationAnswers} from '../models/data.model';
import {SadaCustomValidator} from '../validator/sada-custom-validator';
import {SharedUtil} from '@shared/shared.util';
import { PageInfo } from '../models/page-map';
import { MccssAddressChoiceRequest, 
  MccssAddressMapper, 
  MccssAddressSearchService, 
  MccssAddressSearchStore 
} from '@mccss/pclookup-common-ui';
import { AddressHelper } from '../utils/address-helper';

@Injectable({
  providedIn: 'root'
})
export class AppDataResolver {

  constructor(
    private readonly intakeService: IntakeService,
    private readonly addressSearchService: MccssAddressSearchService,
    private readonly addressStore: MccssAddressSearchStore
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApplicationAnswers> {
    const isResume = route.queryParams?.action === 'resume';
    const processAddress = this.shouldProcessAddress(route, state);

    if (isResume) {
      return this.intakeService.getSavedApplication().pipe(
        tap(data => this.setIntakeFlags(data)),
        switchMap(data => {
          if (processAddress) {
            return this.processAddress(data);
          }
          return of(data);
        })
      );
    } else {
      return this.intakeService.getApplication(route.data.pageId).pipe(
        switchMap(applicationAnswers => {
          if (processAddress) {
            return this.processAddress(applicationAnswers);
          }
          return of(applicationAnswers);
        })
      );
    }
  }

  /**
   * Determines whether to process the address based on route data and query parameters.
   */
  private shouldProcessAddress(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Check if the `useCache` query parameter is present
    const useCache = route.queryParamMap.get('useCache') === 'true';
    const url: string = state.url;

    // Define the target strings to check
    const targetStrings = [PageInfo.addressInfo, PageInfo.addressReview, PageInfo.confirmAddress];
    // Check if the URL contains any of the target strings
    const isAddressPage = targetStrings.some(str => url.includes(str));

    return !useCache && isAddressPage;
  }

  /**
   * Sets various flags in the IntakeService based on the application answers data.
   */
  private setIntakeFlags(data: ApplicationAnswers): void {
    const applicantSponsored = data?.jsonData?.applicantSponsored;
    const spouseSponsored = data?.jsonData?.spouseSponsored;
    const childList = data?.jsonData?.childList;
    const maritalStatus = data?.jsonData?.maritalStatus;
    const childrenLivingWithYou = data?.jsonData?.childrenLivingWithYou;
    const shouldExcludeDbd = SharedUtil.checkIfDbdShouldBeExcluded(data);

    if (applicantSponsored === 'yes' || SadaCustomValidator.isFamilySponsored([spouseSponsored, childList])) {
      this.intakeService.setSponsorshipRequired(true);
    }

    if (maritalStatus === 'Married' || maritalStatus === 'Common Law') {
      this.intakeService.setAppSpouseRequired(true);
    }

    if (childrenLivingWithYou === 'yes') {
      this.intakeService.setAppChildRequired(true);
    }

    if (shouldExcludeDbd) {
      this.intakeService.setShouldExcludeDbd(true);
    }
  }

  /**
   * Processes the application answers by fetching address choices and updating the store.
   */
  private processAddress(applicationAnswers: ApplicationAnswers): Observable<ApplicationAnswers> {
    this.addressStore.resetAll();
    const address = AddressHelper.buildAddressFromAnswers(applicationAnswers);

    if (!address) {
      // Return the applicationAnswers even if no address data is found
      return of(applicationAnswers);
    }

    // Create the request and fetch address choices
    const request: MccssAddressChoiceRequest = MccssAddressMapper.mapEnteredAddressToChoiceRequest(address);
    return this.addressSearchService.searchAddressChoices(request).pipe(
      tap(addressChoicesResponse => {
        // Update the address store with fetched address data and choices
        this.addressStore.setAddressChoices(addressChoicesResponse);
        this.addressStore.setEnteredAddress(address);
      }),
      map(() => applicationAnswers), // Ensure applicationAnswers is the final output
      catchError(error => {
        console.error('Failed to fetch address choices:', error);
        // Return the existing applicationAnswers even if choices retrieval fails
        return of(applicationAnswers);
      })
    );
  }
}
