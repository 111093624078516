<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <form [formGroup]="form" sdDisableAutocomplete>

    <div class="form-group required">

      <sd-radio-question *ngIf="isSingleApplicant"
                         [showError]="showError" [id]="'earned-income-applicant-receive-income'"
                         label="earned-income.applicant.receive.income"
                         [hintText]="'dialog-constant.canadian.type'"
                         [context]="'earned-income.applicant.receive.income.context'"
                         [labelParam]="currentMonthYearLabelParam"
                         [required]="true"
                         controlName="applicantReceiveEarnedIncome">
      </sd-radio-question>

      <sd-multi-option-checkbox-question *ngIf="!isSingleApplicant"
                            [id]="'earned-income.anyone.receive.income'" [options]="applicantOptions"
                            [showLabel]=true
                            [additionalHintText]="'dialog-constant.canadian.type'"
                            [errorMessage]="showError && isAnyOneReceiveEarnedIncomeCBSelected ? 'error.required' : ''"
                            controlName="anyOneReceiveEarnedIncomeCB"
                            [hintText]="'earned-income.select.all'"
                            [labelParam]="currentMonthYearLabelParam">
      </sd-multi-option-checkbox-question>

      <ng-container formGroupName="incomes">

        <ng-container *ngFor="let applicant of checkedChoicesExcludingNone">

          <ng-container *ngIf="getIncomesFormArray(applicant.value).length > 0">

            <div formArrayName="{{getFormArrayNameByApplicantType(applicant.value)}}" class="panel-container">
              <h2 class="ontario-h3">
                <span>{{'earned-income.income.for' | translate}}</span>
                <span [innerHTML]="applicant.label | translate: applicant.labelParam"></span>
              </h2>
              <ng-container *ngFor="let income of getIncomesFormArray(applicant.value).controls; let index=index">

                <div [formGroupName]="index" class="inner-card">
                  <div class="ontario-h4">{{'earned-income.income' | translate: {index: (index + 1)} }}</div>

                  <sd-income-question-panel [parentFormIndex]="index" [applicantType]="applicant.value"
                                            [applicantName]="applicant.labelParam.name"
                                            [showError]="showError" [monthAndYear]="currentMonthYearLabelParam"
                                            [isEarnedIncomeMonthSame]="isEarnedIncomeMonthSame"
                                            [isSingleApplicant]="isSingleApplicant"
                                            [data]="applicationAnswers.jsonData"></sd-income-question-panel>

                  <button *ngIf="getIncomesFormArray(applicant.value).length > 1"
                          [id]="'remove-panel-' + applicant.value+ index"
                          class="ontario-button ontario-button--tertiary" type="button"
                          (click)="removeIncome(applicant.value, index)">{{'earned-income.remove.income' | translate}}</button>
                </div>

              </ng-container>

              <button [id]="'add-panel-' + applicant.value" class="ontario-button ontario-button--secondary"
                      type="button" (click)="addIncome(applicant.value)">
                <span>{{'earned-income.add.income' | translate}}</span>
                <span [innerHTML]="applicant.label | translate: applicant.labelParam"></span>
              </button>

            </div>

          </ng-container>

        </ng-container>

      </ng-container>

      <!-- Child care-->
      <ng-container *ngIf="!isSingleApplicant && checkedChoicesExcludingNone.length > 0 && childCareChildrenOptions.length">
        <sd-radio-question [showError]="showError" [id]="'child-care-pay-for'"
                           label="earned-income.child.care.pay.for"
                           [context]="'earned-income.child.care.pay.for.context'"
                           [required]="true"
                           controlName="anyChildCareExpense">
        </sd-radio-question>

        <div *ngIf="isPayingForChildCare" class="panel-container">

          <sd-multi-option-checkbox-question [id]="'child-care-select-child-pay-for'" [options]="childCareChildrenOptions"
                                label="earned-income.child.care.select.child.pay.for"
                                [showLabel]=true
                                [errorMessage]="showError && isAnyChildCareExpenseCBSelected ? 'error.required' : ''"
                                controlName="anyChildCareExpenseCB">
          </sd-multi-option-checkbox-question>

          <div [formGroupName]="'childCareExpenses'" style="margin-top: 1rem">

            <ng-container *ngFor="let childCareExpense of checkedChildrenForChildCare; let index=index">

              <div [formGroupName]="getChildCareExpenseFGName(childCareExpense.value)" class="inner-card">
                <div class="ontario-h4">{{childCareExpense.label | translate: childCareExpense.labelParam }}</div>
                <sd-child-care-panel [parentFormIndex]="index" [showError]="showError">
                </sd-child-care-panel>
              </div>

            </ng-container>

          </div>

        </div>

      </ng-container>

    </div>

    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>

  </form>

</sd-page-container>
