import { Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { IntakeService } from 'src/app/services/intake.service';
import { TranslateService } from '@ngx-translate/core';
import { Idle } from '@ng-idle/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ExternalRouter } from 'src/app/external.router';
import { ConfigService } from 'src/app/services/config.service';
import { AuthService } from 'src/app/services/auth.service';
import { PageService } from 'src/app/services/page.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { MccssAddressData, MccssAddressReviewComponent, MccssAddressSearchStore } from '@mccss/pclookup-common-ui';
import { PageInfo } from 'src/app/models/page-map';
import { take } from 'rxjs';

@Component({
  selector: 'sd-address-review',
  templateUrl: './address-review.component.html',
  styleUrls: ['./address-review.component.scss']
})
export class AddressReviewComponent extends BaseComponent implements OnInit {

  @ViewChild(MccssAddressReviewComponent) addressReviewComponent!: MccssAddressReviewComponent;

  addressData!: MccssAddressData | null;

  constructor(private readonly router: Router,
    public route: ActivatedRoute,
    public intake: IntakeService,
    @Inject(LOCALE_ID) protected localeId: string,
    public translator: TranslateService,
    public ngZone: NgZone,
    public idle: Idle,
    public dialog: MatDialog,
    public externalRouter: ExternalRouter,
    protected configService: ConfigService,
    protected authService: AuthService,
    protected pageService: PageService,
    protected loadingSpinnerService: LoadingSpinnerService,
    private addressStrore: MccssAddressSearchStore) {
    
      super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.addressReview;
  }

  ngOnInit(): void {
    this.addressStrore.getEnteredAddress$.pipe(
      take(1)
    ).subscribe(address => {
      this.addressData = address
    });
  }

  editAddress() {
    this.router.navigate([`/intake/${PageInfo.addressInfo}`], { queryParams: { useCache: 'true' } });
  }

  continue() {
    this.router.navigate([`/intake/${PageInfo.confirmAddress}`], { queryParams: { useCache: 'true' } });
  }

  onSubmit(toContinue?: boolean): void {}
}
