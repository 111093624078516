import { Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { MccssAddressData, MccssAddressSearchStore, MccssAddressSummaryComponent } from '@mccss/pclookup-common-ui';
import { ExternalRouter } from 'src/app/external.router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { IntakeService } from 'src/app/services/intake.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { PageService } from 'src/app/services/page.service';
import { PageInfo } from 'src/app/models/page-map';
import { Subject, take, takeUntil, tap } from 'rxjs';
import { AddressHelper } from 'src/app/utils/address-helper';
import { ButtonUtil } from 'src/app/utils/button-util';
import { PageBaseComponent } from '../PageBaseComponent';

@Component({
  selector: 'sd-address-summary',
  templateUrl: './address-summary.component.html',
  styleUrls: ['./address-summary.component.scss']
})
export class AddressSummaryComponent extends PageBaseComponent implements OnInit, OnDestroy {

  @ViewChild(MccssAddressSummaryComponent) addressSummary!: MccssAddressSummaryComponent;

  private destroy$ = new Subject<void>();

  addressData!: MccssAddressData | null;

  constructor(private readonly router: Router,
    public route: ActivatedRoute,
    public intake: IntakeService,
    @Inject(LOCALE_ID) protected localeId: string,
    public translator: TranslateService,
    public ngZone: NgZone,
    public idle: Idle,
    public dialog: MatDialog,
    public externalRouter: ExternalRouter,
    protected configService: ConfigService,
    protected authService: AuthService,
    protected pageService: PageService,
    protected loadingSpinnerService: LoadingSpinnerService,
    private addressStrore: MccssAddressSearchStore) {
    
      super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.confirmAddress;
  }

  ngOnInit(): void {
    // Get the application answers from data resolver results
    this.applicationAnswers = this.route.snapshot.data['appData'];

    // Get cached address data
    this.addressStrore.getEnteredAddress$.pipe(
      take(1)
    ).subscribe(address => {
      this.addressData = address
    });
  }
  
  onEditAddress() {
    this.router.navigate([`/intake/${PageInfo.addressInfo}`], { queryParams: { useCache: 'true' } });
  }

  onSubmit(toContinue: boolean): void {
    this.addressSummary.onSubmit().pipe(
      tap(isValid => {
        if (isValid) {
          if (toContinue && !this.authService.isAuthorizedToSave()) {
            // 1. save application answers
            this.populateAddressData();
            this.intakeService.saveApplication(this.applicationAnswers, this.pageId).subscribe(() => {
              this.clearAddressCacheAndNavigateToNextPage();
            });
          } else if (toContinue) {
            // 1. Save to resume
            this.populateAddressData();
            this.saveApplicationToResume(this.applicationAnswers, this.pageId).subscribe(() => {
              this.clearAddressCacheAndNavigateToNextPage();
            });
          } else {
            this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
          }
        }
      }),
      takeUntil(this.destroy$) // Cleanup
    ).subscribe();
  }

  private populateAddressData() {
    const addressAnswers = AddressHelper.buildAddressAnswers(this.addressSummary.addressData);
    AddressHelper.removeAllAddressFields(this.applicationAnswers);
    this.applicationAnswers.jsonData = {...this.applicationAnswers.jsonData, ...addressAnswers };
  }

  private clearAddressCacheAndNavigateToNextPage() {
    this.addressStrore.resetAll();
    this.router.navigate([`/intake/${PageInfo.housingSituation}`])
  }

  postInitializeForm() {}

  preSaveApplication() {}
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
