export const FormControlConfigs= {
  applicant: {
    statusInCanada: {
      name: 'statusInCanada',
      alwaysVisible: true,
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    socialInsuranceNumber: {
      name: 'socialInsuranceNumber',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: false
    },
    noSin: {
      name: 'applicantNoSin',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: false
    },
    certificateOfIndianStatusNumber: {
      name: 'certificateOfIndianStatusNumber',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: false,
      visibleForNonCitizenNonRefugee: false,
      visibleForRefugee: false,
      visibleForGovernmentAssistedRefugee: false,
      visibleForTempResidents: false
    },
    arrivalDateToCanada: {
      name: 'arrivalDateToCanada',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: false
    },
    tenYearsLivedInCanada: {
      name: 'tenYearsLivedInCanada'
    },
    sponsored: {
      name: 'applicantSponsored',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: false,
      visibleForTempResidents: false
    },
    healthCardNumber: {
      name: 'healthCardNumber',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: false
    },
    immigrationFileNumber: {
      name: 'immigrationFileNumber',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: false
    }
  },
  spouse: {
    statusInCanada: {
      name: 'spouseStatusInCanada',
      alwaysVisible: true,
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    noLegalStatusInCanada: {
      name: 'spouseNoLegalStatusInCanada',
      alwaysVisible: false
    },
    liveWithApplicant:{
      name: 'spouseLiveWithApplicant',
      visibleForNoLegalStatusInCanada: false
    },
    socialInsuranceNumber: {
      name: 'spouseSocialInsuranceNumber',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    noSin: {
      name: 'spouseNoSin',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    certificateOfIndianStatusNumber: {
      name: 'spouseCertificateOfIndianStatusNumber',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: false,
      visibleForNonCitizenNonRefugee: false,
      visibleForRefugee: false,
      visibleForGovernmentAssistedRefugee: false,
      visibleForTempResidents: false
    },
    arrivalDateToCanada: {
      name: 'spouseArrivalDateToCanada',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    sponsored: {
      name: 'spouseSponsored',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: false,
      visibleForTempResidents: false
    },
    healthCardNumber: {
      name: 'spouseHealthCardNumber',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    immigrationFileNumber: {
      name: 'spouseImmigrationFileNumber',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    }
  },
  child: {
    statusInCanada: {
      name: 'childStatusInCanada',
      alwaysVisible: true,
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    noLegalStatusInCanada: {
      name: 'childNoLegalStatusInCanada',
      alwaysVisible: false
    },
    liveWithApplicant:{
      name: 'childLiveWithApplicant',
      visibleForNoLegalStatusInCanada: false
    },
    socialInsuranceNumber: {
      name: 'childSocialInsuranceNumber',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    noSin: {
      name: 'childNoSin',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    certificateOfIndianStatusNumber: {
      name: 'childCertificateOfIndianStatusNumber',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: false,
      visibleForNonCitizenNonRefugee: false,
      visibleForRefugee: false,
      visibleForGovernmentAssistedRefugee: false,
      visibleForTempResidents: false
    },
    arrivalDateToCanada: {
      name: 'childArrivalDateToCanada',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    sponsored: {
      name: 'childSponsored',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: false,
      visibleForTempResidents: false
    },
    healthCardNumber: {
      name: 'childHealthCardNumber',
      visibleForCitizenBornInCanada: true,
      visibleForStatusIndian: true,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    },
    immigrationFileNumber: {
      name: 'childImmigrationFileNumber',
      visibleForCitizenBornInCanada: false,
      visibleForStatusIndian: false,
      visibleForNonCanadaBornCitizen: true,
      visibleForNonCitizenNonRefugee: true,
      visibleForRefugee: true,
      visibleForGovernmentAssistedRefugee: true,
      visibleForTempResidents: true
    }
  },
}
