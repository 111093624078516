import { Injectable } from '@angular/core';
import { MccssAddressInjectedConfig } from '@mccss/pclookup-common-ui';


@Injectable({
  providedIn: 'root'
})
export class AddressConfigService {
  private addressConfig!: MccssAddressInjectedConfig;

  /**
   * Sets the address configuration.
   * @param addressConfig - The configuration data for the AddressService.
   */
  setAddressConfig(addressConfig: MccssAddressInjectedConfig): void {
    this.addressConfig = addressConfig;
  }

  /**
   * Retrieves the address service configuration.
   * @returns The AddressServiceConfig object.
   */
  getAddressServiceConfig(): MccssAddressInjectedConfig {
    return this.addressConfig;
  }
}