<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner"
                   [pageTitleInsideComponent]="true">

  <div class="card-container">
    <div class="page-inner-container">
      <mccss-address-lookup [addressData]="addressData"></mccss-address-lookup>
    </div>
  </div>

  <app-mccss-save-continue-button [pageId]="pageId"
    dataE2E="continueBtn"
    (handlePrimaryButtonClick)="onSubmit(true)">
  </app-mccss-save-continue-button>
</sd-page-container>