import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlContainer, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'sd-multi-option-checkbox-question',
  templateUrl: './multi-option-checkbox-question.component.html',
  styleUrls: ['./multi-option-checkbox-question.component.scss']
})
export class MultiOptionCheckboxQuestionComponent implements OnInit, AfterViewInit {
  form: FormGroup;

  @Input()
  controlName = 'items';

  @Input()
  showLabel = true;

  @Input()
  public id: string | undefined;

  @Input()
  public options: CheckboxItem[] = [];

  @Input()
  backgroundClass: string;

  @Input()
  public label: string | undefined;

  @Input()
  public labelParam: any | {};

  @Input()
  public descriptionText: string;

  @Input()
  public displayDivider: boolean;

  @Output()
  toggle = new EventEmitter<any[]>();

  @Input()
  hintText: string | undefined;

  @Input()
  hintExpandableContent: string | undefined;

  @Input()
  public errorMessage;

  @Input()
  additionalHintText: string;

  constructor(public formBuilder: FormBuilder, private translator: TranslateService, public controlContainer: ControlContainer) {}

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.label = this.label ? this.label : this.id;
    this.form = this.controlContainer?.control as FormGroup;
    this.form.addControl(this.controlName, new FormArray([]));
    this.populateCheckBoxes();

    this.items.valueChanges.subscribe(value => {
      const optionsChecked = new Array<any>();
      for (let index = 0; index < this.items.length; index++) {
        // @ts-ignore
        const isOptionChecked = this.items.get(index.toString()).value;
        if (isOptionChecked) {
          const currentOptionValue = this.options[index].value;
          optionsChecked.push(currentOptionValue)
        } else {
        }
      }
      this.toggle.emit(optionsChecked);
    });
  }

  // tslint:disable-next-line:typedef
  populateCheckBoxes() {
    if (this.items.length === 0) {
      this.options.forEach(x => {
        this.items.push(new FormControl(x.checked));
      });
    }
    this.options.forEach(item => {
      const index: number = this.options.findIndex(opt => opt.value === item.value);
      if (index >= 0 && item.checked) {
        // @ts-ignore
        this.items.get(index.toString()).setValue(true);
      }
    });
  }

  ngAfterViewInit() {
  }

  get items(): FormArray {
    return this.form.get(this.controlName) as FormArray;
  }

  get fieldName(): string {
    return this.id?.substr(this.id.indexOf('.') + 1);
  }

  setBackgroundClass() {
    return (this.backgroundClass ? this.backgroundClass : 'sada-checkbox-border-white');
  }

  get formControl(): AbstractControl {
    return this.form.get(this.controlName);
  }
}

export interface CheckboxItem {
  value: string;
  label: string;
  labelParam?: any;
  checked: boolean;
}
