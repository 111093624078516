import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { forkJoin, map, Observable } from "rxjs";

export class MultiTranslateLoader implements TranslateLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly resources: { prefix: string; suffix: string }[]
  ) {}

  public getTranslation(lang: string): Observable<any> {
    return forkJoin(
      this.resources.map(resource =>
        this.http.get(`${resource.prefix}${lang}${resource.suffix}`)
      )
    ).pipe(
      map(response => {
        return response.reduce((acc, res) => {
          return { ...acc, ...res };
        }, {});
      })
    );
  }
}