<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner"
                   [pageTitleInsideComponent]="true">

  <div class="card-container">
    <div class="page-inner-container">
      <mccss-address-review 
        [addressData]="addressData"
        (editAddress)="editAddress()"
        (gotoSummary)="continue()"
      ></mccss-address-review>
    </div>
  </div>

</sd-page-container>